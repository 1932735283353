import React, { useState, useEffect, useRef } from "react";
import "./Checkout.css";
import { useLocation, useParams } from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import api from "../services/api";
import Notification from "../components/Notification/Notification";
import ProblemPage from "../components/Ajuda/ProblemPage/ProblemPage";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckoutForm from "../components/CheckoutForm/CheckoutForm";
import PaymentInfo from "../components/PaymentInfo/PaymentInfo";
import CardPaymentInfo from "../components/CardPaymentInfo/CardPaymentInfo";
import apiPaineLogs from "../services/apiPaineLogs";
import apiPayment from "../services/apiPayment";
import { count } from "console";

interface ParamTypes {
  [key: string]: string;
  marketPlaceId: string;
  idOferta: string;
}

interface Oferta {
  id: number;
  valor: number;
  status: number;
  desc_plano: string;
  desconto: number;
  id_plano: number;
  nome_plano: string;
  id_produto: number;
  nome_produto: string;
}

const Checkout: React.FC = () => {
  const tapagu = require("../images/tapagu.png");
  const { marketPlaceId, idOferta } = useParams<ParamTypes>();
  const [email, setEmail] = useState("");
  const [oferta, setOferta] = useState<Oferta>();
  const [nome, setNome] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [tokenCheckout, setTokenCheckout] = useState("");
  const [pixelFacebook, setPixelFacebook] = useState("");
  const [linkPersonalizado, setLinkPersonalizado] = useState(true);
  const [telefone, setTelefone] = useState("");
  const [cpfCreate, setCpfCreate] = useState("");
  const [qrCodeData, setQRCodeData] = useState<string>("");
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [notifications, setNotifications] = useState<
    { type: "erro" | "sucesso" | "atenção" | "informação"; message: string }[]
  >([]);
  const [desconto, setDesconto] = useState(0);
  const valorComDesconto = oferta ? oferta.valor : 0;
  const [hasError, setHasError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isPayingViaPix, setIsPayingViaPix] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const location = useLocation();

  const generateRandomNumbers = (count: any) => {
    const randomNumbers = [];
    for (let i = 0; i < count; i++) {
      const randomNumber = Math.floor(Math.random() * 101); // Gera um número aleatório entre 0 e 100
      randomNumbers.push(randomNumber);
    }
    return randomNumbers;
  };

  const validateNome = (nome: string) => nome.length >= 5;
  const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
  const validateTelefone = (telefone: string) =>
    telefone.replace(/[^\d]/g, "").length === 11;

  const divRef = useRef<HTMLDivElement>(null);

  const logEvent = async (logData: any) => {
    try {
      await apiPaineLogs.post("/api/create-log-pagamento", logData);
    } catch (error) {
      console.error("Erro ao enviar log");
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const nome = queryParams.get("nome");
    const email = queryParams.get("email");
    const telefone = queryParams.get("telefone");
    const qrcode = queryParams.get("qrcode");
    const paymentQueryId = queryParams.get("pgdata");

    if (nome || email || telefone || qrcode || paymentQueryId) {
      setLinkPersonalizado(true);
      setIsPayingViaPix(true);
      if (nome) setNome(nome);
      if (email) setEmail(email);
      if (telefone) setTelefone(telefone);
      if (qrcode) setQRCodeData(qrcode);
      if (paymentQueryId) setPaymentId(paymentQueryId);
    } else {
      setLinkPersonalizado(false);
    }
  }, [location]);

  useEffect(() => {
    const fetchOferta = async () => {
      try {
        const response = await api.get(
          `/api/checkout/${marketPlaceId}/checkout-oferta/${idOferta}`
        );

        setOferta(response.data.oferta);
        setIsLoaded(true);
        setDesconto(response.data.oferta.desconto);
        setTokenCheckout(marketPlaceId ?? "");
        setPixelFacebook(response.data.pixel.pixel_facebook);

        // Inicialização do Pixel do Facebook
        const options = {
          autoConfig: true, // Configuração automática do Pixel
          debug: false, // Ativar modo debug para verificar logs no console
        };

        const pixelFacebookData = response.data.pixel.pixel_facebook;

        if (pixelFacebookData) {
          try {
            const pixelsString = JSON.parse(pixelFacebookData);
            const pixels = JSON.parse(pixelsString);

            // Inicializa e dispara a visualização de página para cada pixel
            pixels.forEach((pixelId: string) => {
              ReactPixel.init(pixelId, undefined, options);
              ReactPixel.trackSingle(pixelId, "PageView");
            });

            // Após carregar a oferta, disparar o evento InitiateCheckout
            pixels.forEach((pixelId: string) => {
              ReactPixel.trackSingle(pixelId, "InitiateCheckout", {
                content_name: response.data.oferta.nome_produto, // ou outro parâmetro relevante
                content_ids: [response.data.oferta.id], // identificador único da oferta
                currency: "BRL",
                value: response.data.oferta.valor, // valor da oferta
              });
            });

          } catch (error: any) {
            console.error(
              "Erro ao processar os IDs dos pixels:"
            );
          }
        }
      } catch (error: any) {
        console.error("Erro ao buscar oferta");
      }
    };

    if (marketPlaceId && idOferta) {
      fetchOferta();
    }
  }, [marketPlaceId, idOferta, linkPersonalizado]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    const checkPaymentStatus = async () => {
      if (!paymentId) return;

      try {
        const response = await api.get(
          `/api/checkout/${marketPlaceId}/verify-payment/${paymentId}`
        );

        if (response.data.status !== "PAID") return;

        await handlePaidOrder();

        clearInterval(interval);
      } catch (error: any) {
        await logEvent({
          emailCliente: email,
          evento: "Erro ao verificar status de pagamento",
          acao: "Erro",
          descricao: error.message,
          dataEvento: new Date(),
          produto: oferta?.nome_produto,
          plano: oferta?.nome_plano,
          urlOferta: window.location.href,
          marketplace: marketPlaceId,
          infoEvento: "Checkout.checkPaymentStatus",
          codigoErro: error.code,
          responseEvento: error,
        });

        if (error.response && error.response.status === 407) {
          setHasError(true);
        }
        console.error("Erro ao consultar o status do pagamento");
      }
    };

    const handlePaidOrder = async () => {
      const webhookPayload = {
        id: paymentId,
        id_produto: oferta?.id_produto,
        id_plano: oferta?.id_plano,
        type: "order.paid",
        module_name: "TaPagu",
        email,
        name: nome,
        phones: {
          mobile_phone: {
            country_code: "55",
            number: telefone,
            area_code: telefone.slice(0, 2),
          },
        },
        document: String(generateRandomNumbers(11)),
      };

      apiPayment.post("/ta-pagu", webhookPayload);

      trackPurchase();
      redirectToThankYouPage();
    };

    const trackPurchase = async () => {
      if (pixelFacebook) {
        try {
          const pixelsString = JSON.parse(pixelFacebook);
          const pixels = JSON.parse(pixelsString);

          pixels.forEach((pixelId: string) => {
            ReactPixel.trackSingle(pixelId, "Purchase", {
              currency: "BRL",
              value: valorComDesconto,
            });
          });
        } catch (error: any) {
          await logEvent({
            emailCliente: email,
            evento: "Erro ao registrar evento de compra no pixel do Facebook",
            acao: "Erro",
            descricao: error.message,
            dataEvento: new Date(),
            produto: oferta?.nome_produto,
            plano: oferta?.nome_plano,
            urlOferta: window.location.href,
            marketplace: marketPlaceId,
            infoEvento: "Checkout.trackPurchase",
            codigoErro: error.code,
            responseEvento: error,
          });

          console.error("Erro ao rastrear compra:");
        }
      }
    };

    const redirectToThankYouPage = async () => {
      try {
        if (linkPersonalizado) {
          window.location.href =
            "https://www.paguedezpila.com/obrigadoporrenovar";
        } else {
          switch (oferta?.id_produto) {
            case 1:
            case 2:
              window.location.href = "https://www.contabase.xyz/obrigado";
              break;
            case 3:
              window.location.href =
                "https://www.contabase.xyz/obrigado-onlyflix";
              break;
            case 5:
              window.location.href = "https://www.dezpila.net/obrigado";
              break;
            case 13:
              window.location.href = "https://www.dezpila.net/obrigado";
              break;
            case 14:
              window.location.href = "https://playnafe.site/obrigado";
              break;
            default:
              window.location.href = "https://www.dezpila.net/obrigado";
              break;
          }
        }
      } catch (error: any) {
        await logEvent({
          emailCliente: email,
          evento: "Erro ao redirecionar para a página de agradecimento",
          acao: "Erro",
          descricao: error.message,
          dataEvento: new Date(),
          produto: oferta?.nome_produto,
          plano: oferta?.nome_plano,
          urlOferta: window.location.href,
          marketplace: marketPlaceId,
          infoEvento: "Checkout.redirectToThankYouPage",
          codigoErro: error.code,
          responseEvento: error,
        });
        console.error(
          "Erro ao redirecionar para a página de agradecimento:",
          error
        );
      }
    };

    interval = setInterval(checkPaymentStatus, 18000);

    return () => clearInterval(interval);
  }, [
    paymentId,
    oferta,
    nome,
    email,
    cpfCreate,
    telefone,
    valorComDesconto,
    marketPlaceId,
    linkPersonalizado,
  ]);

  const handlePagar = async (paymentMethod: string) => {
    setLoading(true);

    const validationErrors = [
      [validateNome(nome), "O nome deve ter pelo menos 5 caracteres."],
      [validateEmail(email), "Email inválido."],
      [validateTelefone(telefone), "Telefone inválido."],
    ].filter(([isValid]) => !isValid);

    if (validationErrors.length > 0) {
      setNotifications(
        validationErrors.map(([_, message]) => ({
          type: "erro",
          message: String(message),
        }))
      );
      setTimeout(() => setNotifications([]), 3000);
      setLoading(false);
      return;
    }

    if (paymentMethod === "Cartão") {
      setIsPayingViaPix(false);
      setPaymentType("Cartão");
      setLoading(false);
      return;
    }

    if (paymentMethod === "PIX") {
      try {
        const payment = {
          email,
          transactionAmount: valorComDesconto,
          description: oferta?.nome_plano,
          marketPlaceId,
          nome,
          telefone,
          idPlano: oferta?.id_plano,
          productName: oferta?.nome_plano,
          idProduto: oferta?.id_produto,
          nomeProduto: oferta?.nome_produto,
          origin: "checkout",
        };

        const response = await api.post(
          `/api/payment/${marketPlaceId}`,
          payment
        );
        const qrCodeURL =
          response.data.point_of_interaction.transaction_data.qr_code;
        setQRCodeData(qrCodeURL);
        setPaymentId(response.data.id);
        setCpfCreate(response.data.doc);
        setIsPayingViaPix(true);

        if (pixelFacebook) {
          try {
            const pixelsString = JSON.parse(pixelFacebook);
            const pixels = JSON.parse(pixelsString);

            pixels.forEach((pixelId: string) => {
              ReactPixel.trackSingle(pixelId, "AddPaymentInfo", {
                currency: "BRL",
                value: valorComDesconto,
              });
            });
          } catch (error: any) {
            await logEvent({
              emailCliente: email,
              evento: "Erro ao comunicar com o pixel do Facebook",
              acao: "Erro",
              descricao: error.message,
              dataEvento: new Date(),
              produto: oferta?.nome_produto,
              plano: oferta?.nome_plano,
              urlOferta: window.location.href,
              marketplace: marketPlaceId,
              infoEvento: "Checkout.handlePagar.pixelFacebook",
              codigoErro: error.code,
              responseEvento: error,
            });
            console.error("Erro ao rastrear informações de pagamento:", error);
          }
        }

        setLoading(false);
      } catch (error: any) {
        await logEvent({
          emailCliente: email,
          evento: "Erro na criação de pagamento",
          acao: "Erro",
          descricao: error.message,
          dataEvento: new Date(),
          produto: oferta?.nome_produto,
          plano: oferta?.nome_plano,
          urlOferta: window.location.href,
          marketplace: marketPlaceId,
          infoEvento: "Checkout.handlePagar",
          codigoErro: error.code,
          responseEvento: error,
        });

        console.error("Erro ao criar pagamento");
        setLoading(false);
      }
    }
  };

  const [errors, setErrors] = useState({
    nome: "",
    email: "",
    telefone: "",
  });

  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  const isMobileDevice = () => {
    return window.innerWidth <= 768;
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    setNotifications([
      ...notifications,
      { type: "sucesso", message: "Copiado!" },
    ]);
    setTimeout(() => setNotifications([]), 3000);
  };

  return (
    <div ref={divRef} className={`checkout-page-container`}>
      <Notification notifications={notifications} />
      {hasError ? (
        <ProblemPage />
      ) : isPayingViaPix ? (
        <PaymentInfo
          oferta={oferta}
          desconto={desconto}
          qrCodeData={qrCodeData}
          showQRCode={showQRCode}
          handleCopy={handleCopy}
          toggleQRCode={toggleQRCode}
          isMobileDevice={isMobileDevice}
          valorComDesconto={valorComDesconto}
          email={email}
          marketPlaceId={marketPlaceId}
        />
      ) : !isPayingViaPix && paymentType === "Cartão" && oferta ? (
        <CardPaymentInfo
          user={{ email, name: nome, telefone }}
          product={oferta}
        />
      ) : !isLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      ) : oferta === undefined ? (
        <div className="not-found-message">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Desculpe, não há nenhum resultado para essa consulta!</span>
        </div>
      ) : oferta && !oferta.status ? (
        <div className="inactive-offer-message">
          <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Essa oferta não está ativa! Tente novamente mais tarde.</span>
        </div>
      ) : (
        <CheckoutForm
          oferta={oferta}
          desconto={desconto}
          nome={nome}
          setNome={setNome}
          email={email}
          setEmail={setEmail}
          telefone={telefone}
          setTelefone={setTelefone}
          errors={errors}
          handlePagar={handlePagar}
          loading={loading}
        />
      )}
      <div className="checkout-tapagu">
        <a
          href="https://www.tapagu.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <p style={{ margin: 0, fontSize: "12px", color: "black" }}>
            checkout
          </p>
          <img
            src={tapagu}
            alt="Logo Tapagu"
            className="product-logo-ckt"
            style={{ margin: 0 }}
          />
        </a>
      </div>
    </div>
  );
};
export default Checkout;
