import React from 'react';
import InputMask from 'react-input-mask'; // Importe todos os componentes necessários
import './CheckoutForm.css';

function translateNomePlano(nomePlano: string) {
    const regex = /(\d+M)(\d+T)?/;
    const match = nomePlano.match(regex);

    if (match) {
        const meses = match[1].replace('M', ' Mês(es) ');
        const telas = match[2] ? match[2].replace('T', ' Tela(s)') : '';
        return meses + telas;
    }
    return nomePlano;
}

const CheckoutForm = ({ oferta, desconto, nome, setNome, email, setEmail, telefone, setTelefone, errors, handlePagar, loading }: any) => {

    const pixPng = require('../../images/pix.png');
    const cardPng = require('../../images/card.png');
    const [selectedPayment, setSelectedPayment] = React.useState('PIX');

    const handleSelectPayment = (payment: any) => {
        setSelectedPayment(payment);
    };

    return (
        <div className='container-principal'>
            <div className='container-principal'>
                <div className='title-container-principal'>
                    <h2>
                        Finalizar Compra
                    </h2>
                    <i className="fa fa-lock" aria-hidden="true"></i>
                </div>
                <div className="product-info-container-principal">

                <img
                    src={oferta.foto}
                    alt="Produto Logo"
                    className="product-logo-secondary"
                />
                    <span className="product-name-principal"><b>{oferta && oferta.nome_produto}</b></span>
                    <div className='plan-flex-principal'>
                        <span ><b>Plano:</b></span>
                        <span >{translateNomePlano(oferta?.nome_plano)}</span>
                    </div>
                    <div className="product-value-principal">
                        <b>Valor: </b>
                        <div>
                            {desconto > 0 ? (
                                <>
                                    <span style={{ textDecoration: 'line-through' }}>
                                        R$ {((oferta?.valor ? oferta.valor / (1 - desconto / 100) : 0)).toFixed(2)}
                                    </span>
                                    <div>
                                        <span style={{ color: 'green' }}>
                                            R$ {oferta?.valor}
                                        </span>
                                        <span style={{ color: 'green' }}>
                                            <i className="fa fa-arrow-down"></i>
                                        </span>
                                        <span style={{ color: 'green' }}>
                                            {- desconto}%
                                        </span>
                                    </div>
                                </>
                            ) : (
                                `R$ ${oferta?.valor}`
                            )}
                        </div>
                    </div>
                </div>


                <span className='container-span-principal'><b>Preencha os dados abaixo para finalizar a compra e criar a sua conta.</b></span>

                <div className="payment-info-checkout-principal">
                    <input
                        type="text"
                        placeholder="Nome e Sobrenome"
                        className="form-control-checkout-principal"
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    {errors.nome && <span className="error-principal">{errors.nome}</span>}

                    <input
                        type="email"
                        placeholder="Email"
                        className="form-control-checkout-principal"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    {errors.email && <span className="error-principal">{errors.email}</span>}

                    <InputMask
                        mask="(99) 9 9999-9999"
                        value={telefone}
                        onChange={(e) => setTelefone(e.target.value)}
                        placeholder="Telefone"
                        className="form-control-checkout-principal"
                    />
                    {errors.telefone && <span className="error-principal">{errors.telefone}</span>}
                </div>
                <p>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <b> Atenção.</b>
                </p>
                <span className='container-span-attention-principal'><b>Após a confirmação da compra, seus dados de login e informações de acesso carregarão na tela de pagamento.</b></span>

                <div className="payment-options-container">
                    {oferta.metodo === 3 && (
                        <>
                            <button
                                className={selectedPayment === 'PIX' ? 'selected' : ''}
                                onClick={() => handleSelectPayment('PIX')}
                            >
                                <img src={pixPng} alt="Ícone PIX" />
                                PIX
                            </button>
                            <button
                                className={selectedPayment === 'Cartão' ? 'selected' : ''}
                                onClick={() => handleSelectPayment('Cartão')}
                            >
                                <img src={cardPng} alt="Ícone Cartão" />
                                Cartão
                            </button>
                        </>
                    )}
                </div>

                <div className="button-container-principal">
                    <button className="btn-pay-checkout-principal" onClick={() => handlePagar(selectedPayment)} disabled={loading}>
                        {loading ? <i className="fa fa-spinner fa-spin"></i> : <b>PAGAR VIA {oferta.metodo === 1 ? 'PIX' : oferta.metodo === 2 ? 'CARTÃO' : selectedPayment.toUpperCase()}</b>}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default CheckoutForm;