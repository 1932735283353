import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react'; // Importe todos os componentes necessários
import apiPaineLogs from '../../services/apiPaineLogs';

function translateNomePlano(nomePlano: string) {
    const regex = /(\d+M)(\d+T)?/;
    const match = nomePlano.match(regex);

    if (match) {
        const meses = match[1].replace('M', ' Mês(es)');
        const telas = match[2] ? match[2].replace('T', ' Tela(s)') : '';
        return meses + telas;
    }

    return nomePlano;
}

const PaymentInfo = ({ oferta, desconto, qrCodeData, showQRCode, handleCopy, toggleQRCode, isMobileDevice, valorComDesconto, email, marketPlaceId }: any) => {

    const logoProduto = require('../../images/1.png');
    const [showButton, setShowButton] = useState(false);
    
    const logEvent = async (logData: any) => {
        try {
            await apiPaineLogs.post('/api/create-log-pagamento', logData);
        } catch (error) {
            console.error('Erro ao enviar log:', error);
        }
    };
    
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 60000); // 30 segundos

        // Limpeza na desmontagem
        return () => clearTimeout(timer);
    }, []);

    const redirectToThankYouPage = async () => {
        try {
            switch (oferta?.id_produto) {
                case 1:
                case 2:
                    window.location.href = "https://www.contabase.xyz/obrigado";
                    break;
                case 3:
                    window.location.href = "https://www.contabase.xyz/obrigado-onlyflix";
                    break;
                case 5:
                    window.location.href = "https://www.paguedezpila.com/obrigado";
                    break;
                case 14:
                    window.location.href = "https://www.playnafe.site/obrigado";
                    break;
            }
            
            await logEvent({
                emailCliente: email,
                evento: 'Cliente acionou o botão de redirecionamento',
                acao: 'info',
                descricao: `Cliente ${email} acionou o botão de redirecionamento`,
                dataEvento: new Date(),
                produto: oferta?.nome_produto,
                plano: oferta?.nome_plano,
                urlOferta: window.location.href,
                marketplace: marketPlaceId,
                infoEvento: 'PaymentInfo.redirectToThankYouPage',
                codigoErro: null,
                responseEvento: null
            });
        } catch (error: any) {
        
            await logEvent({
                emailCliente: email,
                evento: 'Erro ao redirecionar para página de obrigado',
                acao: 'Erro',
                descricao: error.message,
                dataEvento: new Date(),
                produto: oferta?.nome_produto,
                plano: oferta?.nome_plano,
                urlOferta: window.location.href,
                marketplace: marketPlaceId,
                infoEvento: 'PaymentInfo.redirectToThankYouPage',
                codigoErro: error.code,
                responseEvento: error
            });


            console.error('Erro ao redirecionar para a página de agradecimento:', error);
        }
    };

    return (
        <>
            <div className="product-info-container-secondary">
                <img
                    src={oferta.foto}
                    alt="Produto Logo"
                    className="product-logo-secondary"
                />
                <span className="product-name-secondary"><b>{oferta?.nome_produto}</b></span>
                <div className='plan-flex-secondary'>
                    <span className="product-plan-secondary"><b>Plano:</b> {translateNomePlano(oferta?.nome_plano)}</span>
                </div>
                <span className="product-value-secondary">
                    <b>Valor: </b>
                    {desconto > 0 ? (
                        <>
                            <span style={{ textDecoration: 'line-through' }}>
                                R$ {((oferta?.valor ? oferta.valor / (1 - desconto / 100) : 0)).toFixed(2)}
                            </span>
                            <div>
                                <span style={{ color: 'green' }}>
                                    R$ {oferta?.valor}
                                </span>
                                <span style={{ color: 'green' }}>
                                    <i className="fa fa-arrow-down"></i>
                                </span>
                                <span style={{ color: 'green' }}>
                                    {- desconto}%
                                </span>
                            </div>
                        </>
                    ) : (
                        `R$ ${oferta?.valor}`
                    )}
                </span>
            </div>

            <div className='container-pix-secondary'>
                {isMobileDevice() ? (
                    <>
                        {showQRCode ? (
                            <div className="qr-container-secondary" style={{ backgroundColor: 'white' }}>
                                <p>NO APLICATIVO DO SEU BANCO <b>UTILIZE A OPÇÃO PIX LER QR CODE</b></p>
                                <QRCode value={qrCodeData} size={300} className="qr-size" />
                            </div>
                        ) : (
                            <>
                                <p>NO APLICATIVO DO SEU BANCO <b>UTILIZE A OPÇÃO PIX COPIA E COLA</b></p>
                                <span>{qrCodeData}</span>
                            </>
                        )}
                        {!showQRCode ? <button onClick={() => handleCopy(qrCodeData)}><b>COPIAR CÓDIGO PIX</b></button> : <></>}
                        <button className='container-pix-btn-secondary' onClick={toggleQRCode}>
                            {showQRCode ? <b>ESCONDER QR CODE</b> : <b>MOSTRAR QR CODE</b>}
                        </button>
                        {/* {showButton && <button className='container-pix-btn-thirt'>Já realizou o pagamento?</button>} */}
                    </>
                ) : (
                    <div className="qr-container-secondary">
                        {!showQRCode ? (
                            <div className="qr-container-secondary" >
                                <p>NO APLICATIVO DO SEU BANCO <b>UTILIZE A OPÇÃO LER QR CODE</b></p>
                                <QRCode value={qrCodeData} size={300} className="qr-size" />
                            </div>
                        ) : (
                            <>
                                <p>NO APLICATIVO DO SEU BANCO <b>UTILIZE A OPÇÃO PIX COPIA E COLA</b></p>
                                <span>{qrCodeData}</span>
                            </>
                        )}
                        {showQRCode ? <button onClick={() => handleCopy(qrCodeData)}><b>COPIAR CÓDIGO PIX</b></button> : <></>}
                        <button className='container-pix-btn-secondary' onClick={toggleQRCode}>
                            {showQRCode ? <b>ESCONDER CÓDIGO PIX</b> : <b>MOSTRAR CÓDIGO PIX</b>}
                        </button>
                        <p>
                            Realize o pagamento no valor de R$ {valorComDesconto} e conclua seu cadastro :)
                        </p>
                        {/* {showButton && <button className='container-pix-btn-thirt'>Já realizou o pagamento?</button>} */}
                    </div>
                )}


                <div className='container-pix-attention-secondary'>
                    <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <h3>ATENÇÃO</h3>
                </div>
                <span className='info-secondary'><b><p>Esse código PIX é valido por até 30 minutos.</p></b> Após a confirmação da compra, seus dados de login e informações de acesso carregarão na tela de pagamento.</span>
                <div className="qr-container-secondary" style={{ backgroundColor: 'white' }}>

                    {showButton && <button onClick={redirectToThankYouPage} className='container-pix-btn-thirt'>Já realizou o pagamento?</button>}

                </div>
            </div>
        </>
    );
};

export default PaymentInfo;