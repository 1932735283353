// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* CardPaymentInfo.css */

.card-payment-info {
    width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.card-payment-info label {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.card-payment-info input {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.card-payment-info button {
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
}


.btn-pay-checkout-card {
    margin: 0 auto;
    margin-top: 25px;
    width: 100%;
    height: 40px;
    padding: 0.75rem;
    background-color: #007bff;
    border: none;
    cursor: pointer;
    height: 40px;
    color: #f7fbff;
    border-radius: 25px;
    font-size: 18px;
  }

  `, "",{"version":3,"sources":["webpack://./src/components/CardPaymentInfo/CardPaymentInfo.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB;;;AAGA;IACI,cAAc;IACd,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,cAAc;IACd,mBAAmB;IACnB,eAAe;EACjB","sourcesContent":["/* CardPaymentInfo.css */\n\n.card-payment-info {\n    width: 500px;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.card-payment-info label {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n\n.card-payment-info input {\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n}\n\n.card-payment-info button {\n    padding: 10px;\n    border-radius: 5px;\n    border: none;\n    background-color: #007bff;\n    color: white;\n    cursor: pointer;\n}\n\n\n.btn-pay-checkout-card {\n    margin: 0 auto;\n    margin-top: 25px;\n    width: 100%;\n    height: 40px;\n    padding: 0.75rem;\n    background-color: #007bff;\n    border: none;\n    cursor: pointer;\n    height: 40px;\n    color: #f7fbff;\n    border-radius: 25px;\n    font-size: 18px;\n  }\n\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
