// src/api.ts
import axios from 'axios';

const apiPaineLogs = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_PAINELOGS,
});

// Adiciona um interceptador de requisição
apiPaineLogs.interceptors.request.use((config) => {
    // Obtém o token do .env
    config.headers.Authorization = `Bearer ${process.env.REACT_APP_PAINELOGS_TOKEN}`;
  
    return config;
  });

export default apiPaineLogs; 