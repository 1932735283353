import axios from 'axios';

// Crie uma instância do Axios com a URL base da sua API
const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, 
});

// Adiciona um interceptador de requisição
api.interceptors.request.use((config) => {
  // Obtém o token do .env
  config.headers.Authorization = `Bearer ${process.env.REACT_APP_TOKEN_CHECKOUT}`;

  return config;
});

// Defina aqui qualquer lógica adicional que você precisa para as requisições

export default api;