import React from 'react';
import './ProblemPage.css';
import { TbAlertOctagonFilled  } from 'react-icons/tb';

const ProblemPage: React.FC = () => {
    return (
        <div className="problem-page">
            <TbAlertOctagonFilled  className="error-icon" />
            <h1>Tivemos um Problema</h1>
            <p>Houve um problema ao criar a sua conta.</p>
            <p>Por favor, entre em contato com o responsável pela venda para entender o motivo da não criação da conta e correção do problema.</p>

            
        </div>
    );
};

export default ProblemPage;