import React, { useState, useEffect, useRef } from 'react';
import './CheckoutCard.css';
import { useLocation, useParams } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import api from '../services/api';
import Notification from '../components/Notification/Notification';
import ProblemPage from '../components/Ajuda/ProblemPage/ProblemPage';
import CircularProgress from '@material-ui/core/CircularProgress';
import CheckoutForm from '../components/CheckoutForm/CheckoutForm';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';
import CardPaymentInfo from '../components/CardPaymentInfo/CardPaymentInfo';

interface ParamTypes {
    [key: string]: string;
    marketPlaceId: string;
    idOferta: string;
}

interface Oferta {
    id: number;
    valor: number;
    status: number;
    desc_plano: string;
    desconto: number;
    id_plano: number;
    nome_plano: string;
    id_produto: number;
    nome_produto: string;
}

const Checkout: React.FC = () => {
    const tapagu = require('../images/tapagu.png');
    const { marketPlaceId, idOferta } = useParams<ParamTypes>();
    const [email, setEmail] = useState('');
    const [oferta, setOferta] = useState<Oferta>();
    const [nome, setNome] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [tokenCheckout, setTokenCheckout] = useState('');
    const [pixelFacebook, setPixelFacebook] = useState('');
    const [pixelTikTok, setPixelTikTok] = useState('');
    const [linkPersonalizado, setLinkPersonalizado] = useState(true);
    const [telefone, setTelefone] = useState('');
    const [cpfCreate, setCpfCreate] = useState('');
    const [isQRCodeVisible, setQRCodeVisible] = useState(false);
    const [qrCodeData, setQRCodeData] = useState<string>('');
    const [paymentId, setPaymentId] = useState<string | null>(null);
    const [notifications, setNotifications] = useState<{ type: 'erro' | 'sucesso' | 'atenção' | 'informação', message: string }[]>([]);
    const [desconto, setDesconto] = useState(0);
    const valorComDesconto = oferta ? oferta.valor : 0;
    const [hasError, setHasError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isPayingViaPix, setIsPayingViaPix] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);
    const location = useLocation();

    const validateNome = (nome: string) => nome.length >= 5;
    const validateEmail = (email: string) => /\S+@\S+\.\S+/.test(email);
    const validateTelefone = (telefone: string) => telefone.replace(/[^\d]/g, '').length === 11;

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const nome = queryParams.get('nome');
        const email = queryParams.get('email');
        const telefone = queryParams.get('telefone');
        const qrcode = queryParams.get('qrcode');
        const paymentQueryId = queryParams.get('pgdata');

        if (nome || email || telefone || qrcode || paymentQueryId) {
            setLinkPersonalizado(true);
            setIsPayingViaPix(true);
            setQRCodeVisible(true);
            if (nome) setNome(nome);
            if (email) setEmail(email);
            if (telefone) setTelefone(telefone);
            if (qrcode) setQRCodeData(qrcode);
            if (paymentQueryId) setPaymentId(paymentQueryId);
        } else {
            setLinkPersonalizado(false);
        }
    }, [location]);

    useEffect(() => {
        const fetchOferta = async () => {
            try {
                const response = await api.get(`/api/checkout/${marketPlaceId}/checkout-oferta/${idOferta}`);

                setOferta(response.data.oferta);
                setIsLoaded(true);
                setDesconto(response.data.oferta.desconto);
                setTokenCheckout(marketPlaceId ?? '');
                setPixelFacebook(response.data.pixel.pixelFacebook);

            } catch (error: any) {
                console.error('Erro ao buscar oferta:', error);
            }
        };

        if (marketPlaceId && idOferta) {
            fetchOferta();
        }
    }, [marketPlaceId, idOferta, linkPersonalizado]);

    //CONSULTAR PAGAMENTO
    useEffect(() => {
        let interval: NodeJS.Timeout;

        const checkPaymentStatus = async () => {
            if (paymentId) {
                try {
                    const response = await api.get(`/api/checkout/${marketPlaceId}/verify-payment/${paymentId}`);
                    if (response.data.status === 'PAID') {
                        const checkoutResponse = await api.post(`/api/checkout/${tokenCheckout}`, {
                            valorComDesconto,
                            payment_type: 'PIX',
                            transaction: paymentId,
                            productName: oferta?.nome_plano,
                            name: nome,
                            email: email,
                            doc: cpfCreate,
                            phone_number: telefone,
                            type: 'order.paid',
                            marketPlaceId: marketPlaceId,
                            idPlano: oferta?.id_plano,
                            nomePlano: oferta?.nome_plano,
                            idProduto: oferta?.id_produto,
                            nomeProduto: oferta?.nome_produto
                        });

                        if (pixelFacebook) {
                            try {
                                const pixelsString = JSON.parse(pixelFacebook);
                                const pixels = JSON.parse(pixelsString);
                                pixels.forEach((pixelId: string) => {
                                    ReactPixel.trackSingle(pixelId, 'Purchase', {
                                        currency: 'BRL',
                                        value: valorComDesconto,
                                    });
                                });
                            } catch (error: any) {
                                console.error('Erro ao rastrear compra:', error);
                            }
                        }

                        if (pixelTikTok) {
                            try {
                                const pixelsString = JSON.parse(pixelTikTok);
                                const pixels = JSON.parse(pixelsString);
                                pixels.forEach((pixelId: string) => {
                                    // Injetar o código do pixel do TikTok no HTML do componente
                                    const tiktokPixelCode = `
                                        !function(t,e,i,a){var n,s,c;t=tiktokAnalyticsObject=i,t[t]=t[t]||function(){(t[t].q=t[t].q||[]).push(arguments)},t[t].l=1*new Date,n=e.createElement("script"),s=e.getElementsByTagName("script")[0],n.async=1,n.src=a,s.parentNode.insertBefore(n,s)}(window,document,"ttq","https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=${pixelId}");
                                        ttq.load("${pixelId}");
                                        ttq.page();
                                        ttq.track('Purchase', {
                                            currency: 'BRL',
                                            value: ${valorComDesconto},
                                        });
                                    `;

                                    // Cria um novo elemento de script
                                    const script = document.createElement('script');
                                    script.innerHTML = tiktokPixelCode;
                                    // Adiciona o novo elemento de script ao corpo do documento
                                    document.body.appendChild(script);
                                });
                            } catch (error: any) {
                                console.error('Erro ao rastrear compra:', error);
                            }
                        }

                        if (linkPersonalizado) {
                            window.location.href = "https://clkdmg.site/campaign/renovacao-dezpila-email";
                        }else{
                            window.location.href = "https://www.paguedezpila.com/obrigado";
                        }
                        clearInterval(interval);
                    }
                } catch (error: any) {
                    if (error.response && error.response.status === 407) {
                        setHasError(true)
                    }
                    console.error('Erro ao consultar o status do pagamento:', error);
                }
            }
        };

        if (isQRCodeVisible) {
            interval = setInterval(checkPaymentStatus, 5000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isQRCodeVisible, paymentId, cpfCreate]);

    //CRIAR PAGAMENTO
    const handlePagar = async (paymentMethod: string) => {
        setLoading(true);
        let isValid = true;

        if (!validateNome(nome)) {
            setNotifications([...notifications, { type: 'erro', message: 'O nome deve ter pelo menos 5 caracteres.' }]);
            setTimeout(() => setNotifications([]), 3000);
            isValid = false;
        }

        if (!validateEmail(email)) {
            setNotifications([...notifications, { type: 'erro', message: 'Email inválido.' }]);
            setTimeout(() => setNotifications([]), 3000);
            isValid = false;
        }

        if (!validateTelefone(telefone)) {
            setNotifications([...notifications, { type: 'erro', message: 'Telefone inválido.' }]);
            setTimeout(() => setNotifications([]), 3000);
            isValid = false;
        }

        if (paymentMethod === 'Cartão') {
            setIsPayingViaPix(false);
            setPaymentType('Cartão')
        } else if (paymentMethod === 'PIX') {
            if (isValid) {
                try {
                    const payment = {
                        email,
                        transactionAmount: valorComDesconto,
                        description: oferta?.nome_plano,
                        marketPlaceId,
                        nome,
                        telefone,
                        idPlano: oferta?.id_plano,
                        productName: oferta?.nome_plano,
                        idProduto: oferta?.id_produto,
                        nomeProduto: oferta?.nome_produto,
                    }

                    const response = await api.post(`/api/payment/${marketPlaceId}`, payment);
                    const qrCodeURL = response.data.payment.point_of_interaction.transaction_data.qr_code;
                    setQRCodeData(qrCodeURL);
                    setQRCodeVisible(true);
                    setPaymentId(response.data.payment.id);
                    setCpfCreate(response.data.doc)
                    setIsPayingViaPix(true);

                    if (pixelFacebook) {
                        try {
                            const pixelsString = JSON.parse(pixelFacebook);
                            const pixels = JSON.parse(pixelsString);
                            pixels.forEach((pixelId: string) => {
                                ReactPixel.trackSingle(pixelId, 'AddPaymentInfo', {
                                    currency: 'BRL',
                                    value: valorComDesconto,
                                });
                            });
                        } catch (error: any) {
                            console.error('Erro ao rastrear informações de pagamento:', error);
                        }
                    }

                    if (pixelTikTok) {
                        try {
                            const pixelsString = JSON.parse(pixelTikTok);
                            const pixels = JSON.parse(pixelsString);
                            pixels.forEach((pixelId: string) => {
                                // Injetar o código do pixel do TikTok no HTML do componente
                                const tiktokPixelCode = `
                                !function(t,e,i,a){var n,s,c;t=tiktokAnalyticsObject=i,t[t]=t[t]||function(){(t[t].q=t[t].q||[]).push(arguments)},t[t].l=1*new Date,n=e.createElement("script"),s=e.getElementsByTagName("script")[0],n.async=1,n.src=a,s.parentNode.insertBefore(n,s)}(window,document,"ttq","https://analytics.tiktok.com/i18n/pixel/sdk.js?sdkid=${pixelId}");
                                ttq.load("${pixelId}");
                                ttq.page();
                                ttq.track('AddPaymentInfo', {
                                    currency: 'BRL',
                                    value: ${valorComDesconto},
                                });
                            `;

                                // Cria um novo elemento de script
                                const script = document.createElement('script');
                                script.innerHTML = tiktokPixelCode;
                                // Adiciona o novo elemento de script ao corpo do documento
                                document.body.appendChild(script);
                            });
                        } catch (error: any) {
                            console.error('Erro ao rastrear informações de pagamento:', error);
                        }
                    }

                    setLoading(false);
                } catch (error) {
                    console.error('Erro ao criar pagamento:', error);
                }
            } else {
                setLoading(false);
            }
        }
    };

    const [errors, setErrors] = useState({
        nome: '',
        email: '',
        telefone: '',
    });

    const toggleQRCode = () => {
        setShowQRCode(!showQRCode);
    };

    const isMobileDevice = () => {
        return window.innerWidth <= 768;
    };

    const handleCopy = (text: string) => {
        navigator.clipboard.writeText(text);
        setNotifications([...notifications, { type: 'sucesso', message: 'Copiado!' }]);
        setTimeout(() => setNotifications([]), 3000);
    };

    return (
        <div
            ref={divRef}
            className={`checkout-page-container`}
        >
            <Notification notifications={notifications} />
            {hasError ? <ProblemPage /> : (
                isPayingViaPix ? (
                    <PaymentInfo
                        oferta={oferta}
                        desconto={desconto}
                        qrCodeData={qrCodeData}
                        showQRCode={showQRCode}
                        handleCopy={handleCopy}
                        toggleQRCode={toggleQRCode}
                        isMobileDevice={isMobileDevice}
                        valorComDesconto={valorComDesconto}
                    />
                ) : !isPayingViaPix && paymentType === 'Cartão' && oferta ? (
                    <CardPaymentInfo
                        user={{ email, name: nome, telefone }}
                        product={oferta}
                    />
                ) : (
                    !isLoaded ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                            <CircularProgress />
                        </div>
                    ) : oferta === undefined ? (
                        <div className="not-found-message">
                            <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span>Desculpe, não há nenhum resultado para essa consulta!</span>
                        </div>
                    ) : (
                        oferta && !oferta.status ? (
                            <div className="inactive-offer-message">
                                <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                                <span>Essa oferta não está ativa! Tente novamente mais tarde.</span>
                            </div>
                        ) : (
                            <CheckoutForm
                                oferta={oferta}
                                desconto={desconto}
                                nome={nome}
                                setNome={setNome}
                                email={email}
                                setEmail={setEmail}
                                telefone={telefone}
                                setTelefone={setTelefone}
                                errors={errors}
                                handlePagar={handlePagar}
                                loading={loading}
                            />
                        )
                    )
                )
            )}
            <div style={{ marginTop: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '1px' }}>
                <a href="https://www.tapagu.com/" target="_blank" rel="noopener noreferrer">
                    <p style={{ margin: 0, fontSize: '12px', color: 'black' }}>checkout</p>
                    <img src={tapagu} alt="Logo Tapagu" className="product-logo-ckt" style={{ margin: 0 }} />
                </a>
            </div>
        </div>
    );
};
export default Checkout;