import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Checkout from './Checkout/Checkout';
import CheckoutCard from './CheckoutCard/CheckoutCard';
import './App.css';
import ProblemPage from './components/Ajuda/ProblemPage/ProblemPage';

const AppRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/checkout/:marketPlaceId/oferta/:idOferta" element={<Checkout />} />
        <Route path="/checkout/:marketPlaceId/oferta/:idOferta/ckt-card=880829" element={<CheckoutCard />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
