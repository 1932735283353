// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.problem-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #f8f9fa;
    color: #212529;
    padding: 20px;
}

.problem-page h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
}

.problem-page p {
    font-size: 1.2em;
}

.error-icon {
    width: 120px;
    height: 120px;
    color: #6535a3;
}`, "",{"version":3,"sources":["webpack://./src/components/Ajuda/ProblemPage/ProblemPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;IACzB,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,cAAc;AAClB","sourcesContent":[".problem-page {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n    text-align: center;\n    background-color: #f8f9fa;\n    color: #212529;\n    padding: 20px;\n}\n\n.problem-page h1 {\n    font-size: 2.5em;\n    margin-bottom: 20px;\n}\n\n.problem-page p {\n    font-size: 1.2em;\n}\n\n.error-icon {\n    width: 120px;\n    height: 120px;\n    color: #6535a3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
