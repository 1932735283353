// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment-options-container {
    display: flex;
    justify-content: center;
    padding: 0;
    margin-top: 20px;
    border: 1px solid rgba(0, 0, 0, 0.137);
    border-radius: 5px;
}

.payment-options-container button {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    border: none;
    background: none;
    font-size: 16px;
}

.payment-options-container button img {
    margin-right: 10px;
    width: 30px;
    height: 30px;
}

.payment-options-container button:hover {
    background-color: #e0e0e0;
}

.payment-options-container button.selected {
    background-color: #007bff;
    color: white;
}

.plan-flex-principal {
    display: flex;
    flex-direction: row;
}

.product-value-principal {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {
    .plan-flex-principal {
        flex-direction: column;
    }
    .product-value-principal {
        flex-direction: column;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/CheckoutForm/CheckoutForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,UAAU;IACV,gBAAgB;IAChB,sCAAsC;IACtC,kBAAkB;AACtB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,uDAAuD;IACvD,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI;QACI,sBAAsB;IAC1B;IACA;QACI,sBAAsB;IAC1B;AACJ","sourcesContent":[".payment-options-container {\n    display: flex;\n    justify-content: center;\n    padding: 0;\n    margin-top: 20px;\n    border: 1px solid rgba(0, 0, 0, 0.137);\n    border-radius: 5px;\n}\n\n.payment-options-container button {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    padding: 10px;\n    border-radius: 4px;\n    transition: background-color 0.3s ease, color 0.3s ease;\n    border: none;\n    background: none;\n    font-size: 16px;\n}\n\n.payment-options-container button img {\n    margin-right: 10px;\n    width: 30px;\n    height: 30px;\n}\n\n.payment-options-container button:hover {\n    background-color: #e0e0e0;\n}\n\n.payment-options-container button.selected {\n    background-color: #007bff;\n    color: white;\n}\n\n.plan-flex-principal {\n    display: flex;\n    flex-direction: row;\n}\n\n.product-value-principal {\n    display: flex;\n    flex-direction: row;\n}\n\n@media (max-width: 600px) {\n    .plan-flex-principal {\n        flex-direction: column;\n    }\n    .product-value-principal {\n        flex-direction: column;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
