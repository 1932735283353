import React, { useState, useEffect } from 'react';
import './Notification.css';

interface NotificationProps {
    notifications: { type: 'erro' | 'sucesso' | 'atenção' | 'informação', message: string }[];
}

const Notification: React.FC<NotificationProps> = ({ notifications }) => {
    const [visibleNotifications, setVisibleNotifications] = useState(notifications);

    useEffect(() => {
        const timers: NodeJS.Timeout[] = [];
        visibleNotifications.forEach((notification, index) => {
            if (notification.type !== 'informação') {
                const timer = setTimeout(() => {
                    setVisibleNotifications(prev => prev.filter((_, i) => i !== index));
                }, 5000);
                timers.push(timer);
            }
        });
        return () => timers.forEach(timer => clearTimeout(timer));
    }, [visibleNotifications]);
    
    useEffect(() => {
    setVisibleNotifications(notifications);
}, [notifications]);


    const handleClose = (index: number) => {
        setVisibleNotifications(prev => prev.filter((_, i) => i !== index));
    };

    return (
        <div className="notification-container">
            {visibleNotifications.map((notification, index) => (
                <div key={index} className={`notification ${notification.type}`} onClick={() => handleClose(index)}>
                    {notification.message}
                    {notification.type === 'informação' && <span className="close-btn" onClick={() => handleClose(index)}>X</span>}
                </div>
            ))}
        </div>
    );
};

export default Notification;
