// src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ToastContainer } from 'react-toastify';
import './global.css';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
  site: 'us5.datadoghq.com',
  forwardErrorsToLogs: true,
  sessionSampleRate: 100
});

ReactDOM.render(
  <React.StrictMode>
    <App />
    <ToastContainer position="bottom-right" />
  </React.StrictMode>,
  document.getElementById('root')
);