import React, { useState } from 'react';
import './CardPaymentInfo.css';
import InputMask from 'react-input-mask';
import api from '../../services/api';import Notification from '../Notification/Notification';
;

interface User {
    email: string;
    name: string;
    telefone: string;
}

interface Oferta {
    id: number;
    valor: number;
    status: number;
    desc_plano: string;
    desconto: number;
    id_plano: number;
    nome_plano: string;
    id_produto: number;
    nome_produto: string;
}

interface CardPaymentInfoProps {
    user: User;
    product: Oferta;
}

const CardPaymentInfo: React.FC<CardPaymentInfoProps> = ({ user, product }) => {
    const [cardNumber, setCardNumber] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvv, setCvv] = useState('');
    const [cardHolderName, setCardHolderName] = useState('');
    const [notifications, setNotifications] = useState<{ type: 'erro' | 'sucesso' | 'atenção' | 'informação', message: string }[]>([]);


    const handlePayment = () => {
        const paymentData = {
            card_number: cardNumber,
            card_holder_name: cardHolderName,
            card_expiration_date: expiryDate,
            card_cvv: cvv,
            customer: user,
            product: product,
        };

        api.post('/api/card-payment', paymentData)
            .then(response => {
                // Aqui você pode lidar com a resposta do servidor
            })
            .catch((error) => {
                setNotifications([...notifications, { type: 'erro', message: 'Erro ao criar pagamento.' }]);
                setTimeout(() => setNotifications([]), 3000);
                console.error('Error:', error);
            });
    };

    return (
        <div className="card-payment-info">
            <Notification notifications={notifications} />

            <h2>Informações do Cartão</h2>
            <label>
                Número do Cartão:
                <InputMask
                    mask="9999 9999 9999 9999"
                    value={cardNumber}
                    onChange={(e) => setCardNumber(e.target.value)}
                    placeholder='0000 0000 0000 0000'
                />
            </label>
            <label>
                Data de Vencimento:
                <InputMask
                    mask="99/99"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                    placeholder='Ex: 12/25'
                />
            </label>
            <label>
                CVV:
                <InputMask
                    mask="999"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                    placeholder='000'
                />
            </label>
            <label>
                Nome do Titular:
                <input
                    type="text"
                    placeholder='Nome e Sobrenome'
                    value={cardHolderName}
                    onChange={(e) => {
                        const value = e.target.value;

                        // Verifica se o valor contém algum número
                        if (/\d/.test(value)) {
                            return;
                        }

                        // Converte o valor para maiúsculas e atualiza o estado
                        setCardHolderName(value.toUpperCase());
                    }}
                    onBlur={(e) => {
                        const value = e.target.value;
                        const nameParts = value.split(' ');

                        // Verifica se o valor é composto por nome e sobrenome
                        if (nameParts.length < 2) {
                            alert('Por favor, insira o nome e o sobrenome.');
                            return;
                        }
                    }}
                />
            </label>
            <button onClick={handlePayment} className='btn-pay-checkout-card'><b>EFETUAR PAGAMENTO</b></button>
        </div>
    );
};

export default CardPaymentInfo;